<style scoped>
  #per_page {
    width: 100px;
    display: inline;
  }
</style>
<template>
  <b-card no-body cols="12">
    <b-col>
      <b-row cols="12">
        <b-col md="6">
          <h2
              v-if="title"
              class="text-blue font-weight-bold mt-1"
              v-html="$t(title) ? $t(title) : ''"
          ></h2>

        </b-col>
        <b-col align="end" class="col-md-6 mt-1 pr-2">
          <b-button
              @click="exportTransactions"
              variant="primary"
              v-if="!exportLoading"
          >
            Export
          </b-button>
          <img src="/loader.svg" height="50" v-else/>
        </b-col>
        <b-col md="12">
          <b-row>
            <b-col md="4">
              <label for="transaction_type">Transaction Type</label>
              <b-form-select @change="runFilter" v-model="selectedType" id="transaction_type" :options="typeOptions"></b-form-select>
            </b-col>
            <b-col md="4">
              <label for="start_date">Start Date</label>
              <b-form-datepicker @input="runFilter" id="start_date" v-model="start_date" class="mb-2"></b-form-datepicker>
            </b-col>
            <b-col md="4">
              <label for="end_date">End Date</label>
              <b-form-datepicker @input="runFilter" id="end_date" v-model="end_date" class="mb-2"></b-form-datepicker>
            </b-col>

          </b-row>
        </b-col>
      </b-row>

      <br />
      <b-table
          striped
          responsive
          hover
          class="position-relative table-responsive"
          :per-page="per_page"
          :current-page="0"
          :items="data"
          :fields="headers"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @sort-changed="sortChanged"
          show-empty
      >
        <slot v-for="(_, name) in $slots" :name="name" :slot="name">  </slot>
        <template #head()="scope">
          <div class="text-nowrap">{{ $t(scope.label) }}</div>
        </template>

        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        <template
            v-for="(_, name) in $scopedSlots"
            :slot="name"
            slot-scope="slotData"
        ><slot :name="name" v-bind="slotData" />
        </template>
        <template #empty="scope">
          <div v-if="loader">
            <CustomLoader :show="true" />
          </div>
          <div v-else-if="data.length < 1 && !loader">
            {{ scope.emptyText }}
          </div>
        </template>
        <template #cell(created_at)="data">
            <span>{{
              data.item.created_at | formatDate
            }}</span>
        </template>
        <template #cell(type_description)="data">
          {{ getTransactionFormattedType(data.item.type_description)}}
        </template>
        <template #cell(company)="data">
            <span v-if="data.item.company">{{
              data.item.company.name
            }}</span>
            <span v-else>-</span>
        </template>
        <template #cell(user_id)="data" >
            <span v-if="data.item.company">{{
              data.item.company.main_contact_person[0].user.id
            }}</span>
            <span v-else>-</span>
        </template>
          <template #cell(role)="data">
              <span v-if="data.item.company">{{data.item.company.role}}</span>
              <span v-else>-</span>
          </template>
          <template #cell(currency_id)="data" >
            <span v-if="data.item.currency.name">{{
              data.item.currency.name
            }}</span>
            <span v-else>-</span>
        </template>
        <template #cell(user)="data">
            <span v-if="data.item.company">{{ data.item.company.main_contact_person[0].user.name }} {{ data.item.company.main_contact_person[0].user.last_name }}</span>
            <span>-</span>
        </template>

      </b-table>
      <b-card-body class="d-flex justify-content-end flex-wrap pt-0">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            Show
            <b-form-select @change="runFilter" v-model="per_page" id="per_page" :options="page_options"></b-form-select> items
          </div>
          <b-pagination
              @input="paginateItems"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="per_page"
              aria-controls="my-table"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-col>
  </b-card>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BCardTitle,
        BRow,
        BCol,
        BLink,
        BCard,
    } from "bootstrap-vue";
    import CustomLoader from "@/components/Common/CustomLoader";

    export default {
        name: "Table",
        components: {
            CustomLoader,
            BTable,
            BAvatar,
            BBadge,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BCardBody,
            BCard,
            BRow,
            BCol,
            BLink,
            BCardTitle,
        },
        data() {
            return {
                pageOptions: [3, 5, 12],
                totalRows: 1,
                currentPage: 1,
                sortBy: "id",
                sortDesc: true,
                sortDirection: "asc",
                filter: "",
                tableKey: "initTable",
                filterOn: [],
                loader: false,
                renderPagination: true,
                pageTitle: {},
                headers: [],
                totalItems: 0,
                data: [],
                start_date: '',
                end_date: '',
                compFilter: "all",
                per_page: 200,
                page_options: [
                    { value: 20, text: '20' },
                    { value: 50, text: '50' },
                    { value: 100, text: '100' },
                    { value: 200, text: '200' },
                ],
                typeOptions: [{ value: '', text: 'All Types' }],
                selectedType: '',
                exportLoading: false
            };
        },
        watch: {
            updateData(newValue, oldValue) {
                if (newValue != oldValue) {
                    this.$emit("updateProp");
                    this.paginateItems();
                }
            },
        },
        props: [
            "title",
            "fields",
            "url",
            "showSearch",
            "updateData",
            "page",
            "body",
            "actionButton",
        ],
        computed: {
            sortOptions() {
                return this.headers
                    .filter((f) => f.sortable)
                    .map((f) => ({ text: f.text, value: f.value }));
            },
            rows() {
                return this.totalItems;
            },
        },
        created() {
            this.pageTitle = this.title;
            this.headers = this.fields ? this.fields : [];
            this.getTypeOptions();
            this.paginateItems();
        },
        methods: {
            getTransactionFormattedType(type) {
                let optionText = type;
                if(optionText === 'invoice_paid') {
                    optionText = 'Settled';
                } else if(optionText === 'Purchased') {
                    optionText = 'Purchased partially';
                } else if(optionText === 'Purchase') {
                    optionText = 'Purchased fully';
                }

                return optionText;
            },
            async exportTransactions() {
                this.exportLoading = true;
                try {
                    const response = await this.$http.post('/transactions/admin/export', {
                        responseType: 'blob', // Important to set responseType as 'blob'
                        start_date: this.start_date,
                        end_date: this.end_date,
                        selectedType: this.selectedType,
                    });

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Transaction Report.csv');
                    document.body.appendChild(link);
                    link.click();
                    this.exportLoading = false;
                } catch (error) {
                    console.error('Error exporting CSV:', error);
                    this.exportLoading = false;
                }
            },
            getTypeOptions() {
                this.$http
                    .post(
                        `/transactions/admin/types`
                    )
                    .then((res) => {
                        if (res) {
                          console.log(res);
                          if (res.data.value && res.data.value) {
                                res.data.value.forEach((d) => {
                                    this.typeOptions.push({
                                        value: d.type_description,
                                        text: this.getTransactionFormattedType(d.type_description),
                                    });
                                });
                                this.totalItems = res.data.value.total;
                                this.currentPage = res.data.value.current_page;
                            } else if (res.data.value) {
                                this.data = res.data.value;
                                this.totalItems = res.data.total;
                                this.currentPage = res.data.current_page;
                            }

                            this.$emit("emitNextStep", this.data);
                        }
                        this.loader = false;
                    });
            },
            filterComanies(type) {
                // this.$emit("filterCompanies", type);
                this.compFilter = type;
                if (type != 3) {
                    this.$http
                        .post("/company/admin/list", { role: type })
                        .then((response) => {
                            this.data = response.data.value.data;
                            this.totalItems = response.data.value.total;
                            this.currentPage = response.data.value.current_page;
                        });
                } else {
                    this.paginateItems();
                }
            },
            runFilter() {
                this.currentPage = 1;
                this.paginateItems();
            },
            paginateItems(type) {
                this.loader = true;
                if (type == "clear") {
                    this.filter = "";
                }

                this.data = [];
                if (this.url) {
                  this.$http
                        .post(
                            `${this.url}?page=${this.currentPage}&sortDesc=${
                                this.sortDesc ? "desc" : "asc"
                                }&sortBy=${this.sortBy}&from=reports&search=${this.filter}`,
                            {
                                start_date: this.start_date,
                                end_date: this.end_date,
                                selectedType: this.selectedType,
                                per_page: this.per_page
                            }
                        )
                        .then((res) => {
                            if (res) {
                                if (res.data.value && res.data.value.data) {
                                    this.data = res.data.value.data;
                                    this.totalItems = res.data.value.total;
                                    this.currentPage = res.data.value.current_page;
                                } else if (res.data.value) {
                                    this.data = res.data.value;
                                    this.totalItems = res.data.total;
                                    this.currentPage = res.data.current_page;
                                }

                                this.$emit("emitNextStep", this.data);
                            }
                            this.loader = false;
                        });
                }
            },
            sortChanged(e) {
                this.sortDesc = e.sortDesc;
                this.sortBy = e.sortBy;
                this.data = [];
                if (this.url) {
                    this.$http
                        .post(
                            `${this.url}?sortDesc=${e.sortDesc ? "desc" : "asc"}&sortBy=${
                                e.sortBy ? e.sortBy : e
                                }`
                        )
                        .then((res) => {
                            if (res) {
                                if (res.data.value && res.data.value.data) {
                                    this.data = res.data.value.data;
                                    this.totalItems = res.data.value.total;
                                    this.currentPage = res.data.value.current_page;
                                } else {
                                    this.data = res.data.value;
                                    this.totalItems = res.data.value.total;
                                    this.currentPage = res.data.value.current_page;
                                }
                            }
                        });
                }
            },

            filterItems() {
                this.data = [];
                if (this.url) {
                    this.$http
                        .post(
                            `${this.url}?search=${this.filter}&sortDesc=${
                                this.sortDesc ? "desc" : "asc"
                                }&sortBy=${this.sortBy}`
                        )
                        .then((res) => {
                            if (res) {
                                if (res.data.value && res.data.value.data) {
                                    this.data = res.data.value.data;
                                    this.totalItems = res.data.value.total;
                                    this.currentPage = res.data.value.current_page;
                                } else {
                                    this.data = res.data.value;
                                    this.totalItems = res.data.value.total;
                                    this.currentPage = res.data.value.current_page;
                                }
                            }
                        });
                }
            },
        },
    };
</script>
<style>
  .company-btn {
    margin-right: 7px;
  }
  .compnay-grp-btn{
    display: inline-block;
  }
</style>

